<template>
<div class="reset-box">
    <div style="font-weight:300;font-size:36px;">Set New Password</div>
    <b-form class="pl-5 pt-1" @submit="onSubmit">
        <b-form-group
        class="d-flex flex-column mb-1"
        id="new-password-label"
        label="New password:"
        label-for="new-password"
        description="Please enter a new password."
        >
        <b-form-input
            id="new-password"
            v-model="form.password"
            type="password"
            autocomplete="new-password"
            placeholder="Password"
            required
        ></b-form-input>
        </b-form-group>
        <div class="text-right">
        <b-button variant="outline-primary" class="avo-basic-btn" type="submit">Submit</b-button>
        </div>
    </b-form>
</div>
</template>

<script>
import { make_url, unauthenticated_request, alertsMixin } from '../../common.js'

export default {
  name: 'SetNewPassword',
  mixins: [alertsMixin],
  data: function(){
      return {
          form: {
              password: '',
          }
      }
  },
  methods: {
    onSubmit(event) {
        event.preventDefault();
        console.log("submitting...");

        const vm = this;

        const url = make_url("/authentication/reset");

        const params = new URLSearchParams(window.location.search);
        
        if (params.has('token')) {
            const data = {
            new_password: this.form.password, 
            reset_token: params.get('token')
            }

            const promise = unauthenticated_request({
                method: "post",
                url: url,
                data: data
            }).then(function(){
                    vm.$emit('passwordChanged');
                    vm.setShowAlert(false);
                    vm.showAlert = false;
                }, function(error){
                    vm.setShowAlert(true, error);
            })    
            .then(() => window.location.replace("/login/"));
            return promise;
        } else {
            console.log("Didn't find a token");
            return
        }

    }
  }
}
</script>

<style scoped>
.reset-box {
    display: flex;
    flex-direction: column;
    max-width: 300px;
}
input {
    border: 1px solid #C9D2DF;
    border-radius: 5px !important;
    max-width:650px;
    height: 30px;
}
label {
    font-size: 14px;
}
.avo-basic-btn {
    width: 100% !important;
}
</style>